import React from "react";
import Img from "gatsby-image";
import { Link, graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";

const StyledPortfolioThumbnail = styled.div`
  width: 100%;
  position: relative;
  display: flex;

  @media only screen and (min-width: 740px) {
    width: 50%;
  }

  @media only screen and (min-width: 1600px) {
    width: 33.33%;
  }

  .gatsby-image-wrapper,
  img {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: cover;
  }

  .dummy {
    margin-top: 66%;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    transition: opacity 250ms ease-in-out;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    @media only screen and (min-width: 740px) {
      opacity: 0;

      &:hover {
        opacity: 1;
      }
    }

    span {
      color: #fff;
      border: 2px solid #fff;
      padding: 8px 20px;
      text-transform: uppercase;
      text-align: center;
      font-weight: 400;
    }
  }
`;

const StyledPortfolioThumbnails = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const PortfolioThumbnail = ({ image, linkCopy, linkTo }) => {
  return (
    <StyledPortfolioThumbnail>
      <div className="dummy" />
      {image.map(img => (
        <Img fluid={img.childImageSharp.fluid} alt={linkCopy} />
      ))}
      <Link to={linkTo}>
        <div className="overlay">
          <span>{linkCopy}</span>
        </div>
      </Link>
    </StyledPortfolioThumbnail>
  );
};

const PortfolioThumbnails = () => {
  const data = useStaticQuery(graphql`
    query {
      images: allFile(filter: { relativeDirectory: { eq: "portfolio" } }) {
        nodes {
          name
          childImageSharp {
            fluid(maxWidth: 720) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `);

  return (
    <StyledPortfolioThumbnails>
      <PortfolioThumbnail
        image={data.images.nodes.filter(
          image => image.name === "tiling-and-slating-thumb"
        )}
        linkCopy="Tiling and slating"
        linkTo="/portfolio/tiling-and-slating"
      />
      <PortfolioThumbnail
        image={data.images.nodes.filter(
          image => image.name === "roofing-windows-thumb"
        )}
        linkCopy="Roofing windows, projects and repairs"
        linkTo="/portfolio/roofing-projects"
      />
      <PortfolioThumbnail
        image={data.images.nodes.filter(
          image => image.name === "flat-roofing-thumb"
        )}
        linkCopy="Flat roofing"
        linkTo="/portfolio/flat-roofing"
      />
      <PortfolioThumbnail
        image={data.images.nodes.filter(
          image => image.name === "leadwork-thumb"
        )}
        linkCopy="Leadwork"
        linkTo="/portfolio/leadwork"
      />
      <PortfolioThumbnail
        image={data.images.nodes.filter(image => image.name === "upvc-thumb")}
        linkCopy="uPVC Fascias, Soffits and Guttering"
        linkTo="/portfolio/upvc-facias-soffits-and-guttering"
      />
      <PortfolioThumbnail
        image={data.images.nodes.filter(image => image.name === "image4")}
        linkCopy="Chimney Services"
        linkTo="/portfolio/chimney-services"
      />
    </StyledPortfolioThumbnails>
  );
};

export default PortfolioThumbnails;
