import React from "react";

import Layout from "../components/Layout";
import SEO from "../components/seo";
import IntroCopy from "../components/IntroCopy";
import PortfolioThumbnails from "../components/PortfolioThumbnails";

const PortfolioPage = () => (
  <Layout>
    <main>
      <SEO title="Portfolio" />
      <IntroCopy
        headline="Our portfolio"
        body="Our skilled tradesmen are talented in all aspects of roofing works. Feel free to look through various examples of our work."
        topPadding
      />
      <PortfolioThumbnails />
    </main>
  </Layout>
);

export default PortfolioPage;
